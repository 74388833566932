import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import important from "../src/assests/i-icon.svg";

const DialogBox = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const goToMainSite = () => {
    window.location.href = 'https://app.paypaw.cards/'
  };

  return (
    <div className="absolute bg-nav-bar flex justify-center items-center mx-8 sm:mx-0 rounded-lg gray-border w-96 md:w-607">
      <div className="text-white">
        <div className="flex flex-col items-center">
          {/* heading */}
          <div className="flex items-center justify-start p-4">
            <h2 className="text-2xl font-bold mb-3">
              Welcome to PayPaw!
            </h2>
          </div>
          {/* body */}
          <div className="flex flex-col items-center px-4 pb-4 w-96 md:w-607">
            <p className="pb-5">
              Please visit our main site - <a href="https://app.paypaw.cards/" target="_blank">https://app.paypaw.cards/</a> or click below to be redirected automatically. This site is only used for development.
            </p>

            
            <button
              className="pink-btn"
              onClick={goToMainSite}
            >
              Visit Main Site
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;

