
const BgTheme = ({children}) => {
    return (
        <div className="bg-solid h-full w-full">
           {children}
        </div>
    )
}

export default BgTheme;
