import React, { useState, FC, useMemo, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DialogBox from "./DialogBox";
import "@fontsource/inter";

function App() {

  return (
    <Router>
      <div className="flex h-lvh">
        <div className="w-full overflow-x-hidden overflow-y-auto sm:overflow-y-hidden">
          {/* content-area*/}
          <div className="relative h-full flex items-center justify-center p-2">
            <Routes>
              <Route path="/" element={<DialogBox />} exact />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
